import React from 'react'
import Box from '@material-ui/core/Box'
import Copyright from '../components/Copyright'
import Header from '../components/Header'
import { AUTH_URL } from '../helpers/auth'

export default function Login({ next }) {
  const authUrl = next
    ? `${AUTH_URL}&state=${encodeURIComponent(next)}`
    : AUTH_URL

  return (
    <>
      <Header />
      <Box style={{ textAlign: 'center' }} mt={8}>
        <a href={authUrl}>
          <img
            alt="Sign in with Slack"
            height="40"
            width="172"
            src="https://platform.slack-edge.com/img/sign_in_with_slack.png"
            srcSet="https://platform.slack-edge.com/img/sign_in_with_slack.png 1x, https://platform.slack-edge.com/img/sign_in_with_slack@2x.png 2x"
          />
        </a>
        <Copyright />
      </Box>
    </>
  )
}
