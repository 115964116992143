import React from 'react'
import { css } from '@emotion/core'
import HashLoader from 'react-spinners/HashLoader'

export default function LoadingSpinner() {
  const overlayStyle = css`
    height: 100%;
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #efefef;
    opacity: 0.8;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  `

  return (
    <div css={overlayStyle}>
      <HashLoader />
    </div>
  )
}
