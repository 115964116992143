import * as React from 'react'
import classNames from 'classnames'
import Paper from '@material-ui/core/Paper'
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto'

import './Dropzone.scss'

export const Dropzone = React.forwardRef((props, ref) => {
  const { children, isUploading } = props
  const [isDraggingOver, setIsDraggingOver] = React.useState(false)
  const fileInputRef = React.useRef(null)

  const onDragOver = (event) => {
    event.preventDefault()
    setIsDraggingOver(true)
  }

  const onDragLeave = (event) => {
    event.preventDefault()
    setIsDraggingOver(false)
  }

  const bubbleUpUploadedFiles = (files) => {
    if (!files.length) {
      return
    }

    props.onFilesAdded && props.onFilesAdded(files)
  }

  const onDrop = (event) => {
    event.preventDefault()
    const files = event.dataTransfer.files
    files.length && bubbleUpUploadedFiles(files)
    setIsDraggingOver(false)
  }

  const preventDefault = (event) => event.preventDefault()

  const onChange = (event) => {
    const files = event.target.files
    if (!files) {
      return
    }
    bubbleUpUploadedFiles(files)
  }

  const openFileDialog = () => {
    const { current } = fileInputRef
    current && current.click()
  }

  // Prevent drop outside the dropzone
  React.useEffect(() => {
    window.addEventListener('dragover', preventDefault, false)
    window.addEventListener('drop', preventDefault, false)
    return () => {
      window.removeEventListener('dragover', preventDefault, false)
      window.removeEventListener('drop', preventDefault, false)
    }
  }, [])

  const conditionalClasses = {
    draggingOver: isDraggingOver,
    'color-primary': isDraggingOver,
    'border-color-primary': isDraggingOver,
  }

  const dropZoneProps = {
    onDragEnter: onDragOver,
    onDragLeave: onDragLeave,
    onDrop: onDrop,
    onClick: openFileDialog,
  }

  return (
    <Paper
      variant="outlined"
      square
      className={classNames('dropzone', conditionalClasses)}
      {...(!isUploading ? dropZoneProps : undefined)}
    >
      {isDraggingOver ? <DropzoneContent /> : children}
      <input
        ref={fileInputRef}
        className="file-input"
        type="file"
        multiple
        onChange={onChange}
      />
      {!isDraggingOver && !isUploading && (
        <div className="upload-box">
          <AddAPhotoIcon />
          <p>Drag and drop or click here to browse.</p>
        </div>
      )}
    </Paper>
  )
})

const DropzoneContent = () => {
  return <div className="dropzone-content">Drop Here!</div>
}
