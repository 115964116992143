import React from 'react'
import { useApi } from '../components/Api'
import Header from '../components/Header'
import { AUTH_URL } from '../helpers/auth'
import { Link, useRouteNode } from 'react-router5'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'

const PostNotAllowed = () => {
  const { currentUser } = useApi()
  const { route } = useRouteNode()

  const authUrl = route.params.next
    ? `${AUTH_URL}&state=${encodeURIComponent(route.params.next)}`
    : AUTH_URL

  return (
    <>
      <Header loggedUser={currentUser} />
      <Link routeName="home" className="post-back-link">
        <ArrowBackIcon />
        Back to timeline
      </Link>
      <div className="post-container">
        <div className="post-box">
          <p style={{ textAlign: 'center', fontSize: '1.2em' }}>
            It looks like this post is not from this workspace, try logging-in
            again with Slack and select the appropriate workspace from the top
            right corner.
          </p>
          <div className="back-to-slack">
            <a href={authUrl}>
              Log into another workspace
              <ArrowForwardIcon />
            </a>
          </div>
        </div>
      </div>
    </>
  )
}

export default PostNotAllowed
