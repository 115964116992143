import React from 'react'
import { useApi } from './Api'
import AirgongLogo from '../images/AirgongWhite.svg'
import { useNavigationCallback } from '../components/Router'
import Link from '@material-ui/core/Link'

export default function Header({ loggedUser }) {
  const { logout } = useApi()
  const goToLogin = useNavigationCallback('login')

  const handleLogoutClick = (e) => {
    logout()
    return goToLogin(e)
  }

  return (
    <header
      style={{
        backgroundColor: '#202020',
        height: 58,
        verticalAlign: 'middle',
        zIndex: 5,
      }}
    >
      <div
        style={{
          maxWidth: 1117,
          margin: '0 auto',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <Link
            style={{
              verticalAlign: 'center',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
            color="inherit"
            href="/"
          >
            <img
              style={{
                color: 'white',
                verticalAlign: 'center',
                marginBottom: 0,
              }}
              src={AirgongLogo}
              width="34px"
              height="34px"
              alt="Airgong Logo"
            />
            <h1
              style={{
                color: 'white',
                fontFamily: 'lato',
                verticalAlign: 'center',
                display: 'inline',
                margin: 9,
              }}
            >
              Airgong
            </h1>
          </Link>
        </div>
        {loggedUser && (
          <div
            style={{
              color: 'white',
              display: 'flex',
              fontSize: 14,
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            <span onClick={handleLogoutClick} style={{ fontWeight: 600 }}>
              {loggedUser.user_name}
            </span>
            <span>{loggedUser.email}</span>
          </div>
        )}
      </div>
    </header>
  )
}
