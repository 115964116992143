import React, { useRef, useEffect, useState } from 'react'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera'
import CancelIcon from '@material-ui/icons/Cancel'
import FlipCameraIosIcon from '@material-ui/icons/FlipCameraIos'
import Webcam from 'webcam-easy'
import LoadingSpinner from './LoadingSpinner'
import { reportError } from '../Logger'

import './PhotoTaker.scss'

const COUNTDOWN_INIT_VALUE = 5

export default function PhotoTaker({ onFinish }) {
  const [camStarted, setCamStarted] = useState(false)
  const [photoTaken, setPhotoTaken] = useState(null)
  const [countdownValue, setCountdownValue] = useState(0)

  const videoElementRef = useRef(null)
  const canvasElementRef = useRef(null)
  const webcamRef = useRef(null)

  useEffect(() => {
    if (videoElementRef.current) {
      webcamRef.current = new Webcam(
        videoElementRef.current,
        'user',
        canvasElementRef.current
      )
      webcamRef.current.start().catch(reportError)

      videoElementRef.current.addEventListener('canplay', () =>
        setCamStarted(true)
      )
    }
  }, [])

  const snap = () => {
    const pic = webcamRef.current.snap()
    webcamRef.current.stop()
    setPhotoTaken(pic)
  }

  const stopCam = () => {
    webcamRef.current.stop()
    onFinish && onFinish()
  }

  const flipCam = () => {
    webcamRef.current.flip()
    webcamRef.current.stream()
  }

  const retake = () => {
    setPhotoTaken(null)
    setCamStarted(false)

    const canvas = canvasElementRef.current
    const context = canvas.getContext('2d')
    context.clearRect(0, 0, canvas.width, canvas.height)

    webcamRef.current.stream()
  }

  const startCountdown = () => {
    setCountdownValue(COUNTDOWN_INIT_VALUE)
    countdown(COUNTDOWN_INIT_VALUE, snap)
  }

  const countdown = (curval, cb) => {
    if (curval > 0) {
      setTimeout(() => {
        setCountdownValue(curval - 1)
        countdown(curval - 1, cb)
      }, 1000)
    } else {
      cb && cb()
    }
  }

  const iconStyle = {
    fontSize: '2.5em',
    cursor: 'pointer',
    margin: 10,
  }

  const confirmationButtons = (
    <>
      <Button variant="contained" onClick={retake} style={{ marginRight: 10 }}>
        Retake
      </Button>
      <Button
        variant="contained"
        color="primary"
        onClick={() => onFinish && onFinish(photoTaken)}
      >
        Looks Good!
      </Button>
    </>
  )

  const cameraButtons = (
    <>
      <CancelIcon onClick={stopCam} style={iconStyle} titleAccess="Cancel" />
      <PhotoCameraIcon
        onClick={startCountdown}
        style={iconStyle}
        titleAccess="Take Picture"
      />
      <FlipCameraIosIcon
        onClick={flipCam}
        style={iconStyle}
        titleAccess="Flip Camera"
      />
    </>
  )

  return (
    <div className="webcam-app">
      {!camStarted && <LoadingSpinner />}
      <video
        className="webcam"
        ref={videoElementRef}
        autoPlay
        playsInline
      ></video>
      <canvas className="canvas" ref={canvasElementRef}></canvas>
      {camStarted && (
        <div className="controls">
          {countdownValue > 0 ? (
            <Typography variant="h1" component="h2" align="center">
              {countdownValue}
            </Typography>
          ) : photoTaken ? (
            confirmationButtons
          ) : (
            cameraButtons
          )}
        </div>
      )}
    </div>
  )
}
