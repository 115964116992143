import React from 'react'
import { useApi } from '../components/Api'
import Header from '../components/Header'
import { useRouteNode } from 'react-router5'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'

const PermissionsUpdated = () => {
  const { currentUser, resumePost } = useApi()
  const { route } = useRouteNode()
  const { postId } = route.params

  async function onContinueClick() {
    await resumePost(postId)
    window.location = 'slack://open'
  }

  return (
    <>
      <Header loggedUser={currentUser} />
      <div className="post-container">
        <div className="post-box">
          <p style={{ textAlign: 'center', fontSize: '1.2em' }}>
            App permissions were updated, you can now return to Slack by
            clicking the button below and continue where you left off!
          </p>
          <div className="back-to-slack">
            <button
              onClick={onContinueClick}
              style={{
                background: 'transparent',
                border: 'none',
                display: 'flex',
                lineHeight: 'unset',
                outline: 'none',
                margin: 'auto',
                marginTop: 5,
                cursor: 'pointer',
                fontSize: '1em',
                color: '#0645ad',
              }}
            >
              Continue on Slack
              <ArrowForwardIcon />
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export default PermissionsUpdated
