import React from 'react'
import Typography from '@material-ui/core/Typography'
import Link from '@material-ui/core/Link'

export default function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://airgong.com/">
        Airgong
      </Link>{' '}
      {new Date().getFullYear()}
      {' | '}
      <Link color="inherit" href="/terms">
        Terms
      </Link>{' '}
      {' | '}
      <Link color="inherit" href="/privacy">
        Privacy
      </Link>{' '}
    </Typography>
  )
}
