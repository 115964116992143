import React from 'react'
import { useApi } from '../components/Api'
import { router } from '../components/Router'
import Header from '../components/Header'
import Emoji from 'react-emoji-render'
import { useRouteNode, Link } from 'react-router5'
import { format } from 'date-fns'
import b64ToBlob from 'b64-to-blob'
import Grid from '@material-ui/core/Grid'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import DeleteIcon from '@material-ui/icons/Delete'
import CameraAltIcon from '@material-ui/icons/CameraAlt'
import { useIsMounted, useAsyncEffect } from '../hooks'
import LoadingSpinner from '../components/LoadingSpinner'
import ImagesUpload, { MAX_NUMBER_OF_IMAGES } from '../components/ImagesUpload'
import PhotoTaker from '../components/PhotoTaker'
import { getTeamCategoryRenderString } from '../helpers'

import './post.scss'

const Post = () => {
  const { route } = useRouteNode('post')
  const {
    getPostById,
    deletePostImage,
    currentUser,
    uploadPhotoTaken,
  } = useApi()
  const [loading, setLoading] = React.useState(true)
  const [post, setPost] = React.useState(undefined)
  const [uploadedImages, setUploadedImages] = React.useState([])
  const [isCameraOn, setIsCameraOn] = React.useState(false)

  const isMounted = useIsMounted()
  const query = window.location.search
  const isSlackBounce = query.includes('src=slack')
  let action
  if (query.includes('action=upload_photo')) {
    action = 'upload_photo'
  } else if (query.includes('action=take_photo')) {
    action = 'take_photo'
  }

  useAsyncEffect(async () => {
    if (route.params.postId) {
      try {
        const post = await getPostById(route.params.postId)

        if (isMounted()) {
          setPost(post)
          setLoading(false)
        }
      } catch (err) {
        if (err.message === 'Forbidden') {
          const next = window.location.pathname + window.location.search
          router.navigate('post-not-allowed', { next })
          return
        }

        if (isMounted()) {
          setLoading(false)
        }
      }
    }

    if (action === 'take_photo') {
      setIsCameraOn(true)
    }
  }, [])

  const deleteImage = async (url) => {
    if (window.confirm('Are you sure to delete this image?')) {
      setLoading(true)
      await deletePostImage(post.id, url)
      const updatedPost = await getPostById(route.params.postId)
      if (isMounted()) {
        setUploadedImages([])
        setPost(updatedPost)
        setLoading(false)
      }
    }
  }

  const finishedTakingPhoto = async (base64Image) => {
    setIsCameraOn(false)

    if (base64Image) {
      setLoading(true)
      const b64Data = base64Image.split(',')[1]
      // converts the base64 image generated from the webcam into a blob (binary object)
      // so we can upload it to the backend
      const blob = b64ToBlob(b64Data, 'image/png')
      const result = await uploadPhotoTaken(post.id, blob)
      console.log(result)
      setUploadedImages([...uploadedImages, result])
      setLoading(false)
    }
  }

  if (!route.params.postId) {
    return null
  }

  if (loading) {
    return <LoadingSpinner />
  }

  if (post === null) {
    return 'Post does not exist'
  }

  const gallery = (post.gallery || []).concat(uploadedImages)
  const shouldRenderElementForAction = (actionName) =>
    (!action || action === actionName) && gallery.length < MAX_NUMBER_OF_IMAGES

  return (
    <>
      <Header loggedUser={currentUser} />
      {!isSlackBounce && (
        <Link routeName="home" className="post-back-link">
          <ArrowBackIcon />
          Back to timeline
        </Link>
      )}
      <div className="post-container">
        <div className="post-box">
          <div className="headline">
            {post.user_name} rang the Airgong in{' '}
            {post.channel_name ? '#' + post.channel_name : ' a private IM'}{' '}
            {getTeamCategoryRenderString(post.team, post.subcategory)}
          </div>

          <div className="post-content">
            <img src={post.avatar_url} className="avatar" alt="Avatar" />
            <div>
              <span className="username">{post.user_name}</span>
              <span className="timestamp">
                {format(new Date(post.created_at), ' hh:mm a')}
              </span>
              <div className="celebration">
                <Emoji text={post.message} />
                {post.media_url && (
                  <img
                    src={post.media_url}
                    className="celebration-gif"
                    alt="Celebration Media"
                  />
                )}
              </div>
            </div>
          </div>

          {shouldRenderElementForAction('take_photo') && (
            <button
              onClick={() => setIsCameraOn(true)}
              className="btn-snap-photo"
            >
              <CameraAltIcon />
              Snap a selfie
            </button>
          )}

          <Grid container spacing={1}>
            {gallery
              .filter((img) => !!img)
              .map((g) => (
                <Grid item key={g.id}>
                  <img
                    key={g.id}
                    className="gallery-image"
                    src={g.image_url}
                    alt="gallery item"
                  />
                  <button
                    onClick={() => deleteImage(g.image_url)}
                    className="delete-image-btn"
                  >
                    <DeleteIcon />
                    delete image
                  </button>
                </Grid>
              ))}

            {shouldRenderElementForAction('upload_photo') && (
              <Grid item>
                <ImagesUpload
                  postId={post.id}
                  curImagesLength={post.gallery ? post.gallery.length : 0}
                  onUploadFinish={(images) =>
                    setUploadedImages(uploadedImages.concat(images))
                  }
                />
              </Grid>
            )}
          </Grid>

          {(!action || action === 'take_photo') && isCameraOn && (
            <PhotoTaker onFinish={finishedTakingPhoto} />
          )}

          {isSlackBounce && (
            <div className="back-to-slack">
              <a
                href="slack://open"
                onClick={() =>
                  window.setTimeout(() => router.navigate('home'), 2000)
                }
              >
                Continue on Slack
                <ArrowForwardIcon />
              </a>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default Post
