import React from 'react'
import CssBaseline from '@material-ui/core/CssBaseline'
import Router, { router } from './components/Router'
import { ApiContext, Api } from './components/Api'
import SEO from './components/seo'
import { useIsMounted, useAsyncEffect } from './hooks'
import { RouterProvider } from 'react-router5'
import { transitions, positions, Provider as AlertProvider } from 'react-alert'
import AlertTemplate from 'react-alert-template-mui'

const alertOptions = {
  position: positions.TOP_CENTER,
  timeout: 5000,
  offset: '30px',
  transition: transitions.SCALE,
}

const IndexPage = () => {
  const [currentUser, setCurrentUser] = React.useState(null)
  const isMounted = useIsMounted()

  useAsyncEffect(async () => {
    if (Api.loggedIn()) {
      const response = await Api.getMe()
      if (isMounted()) {
        setCurrentUser(response)
      }
    }
  }, [])

  return (
    <div
      style={{
        display: 'flex',
        minHeight: '100vh',
        backgroundColor: 'rgb(242, 242, 242)',
        flexDirection: 'column',
      }}
    >
      <SEO title="Home" />
      <CssBaseline />
      <ApiContext.Provider value={{ ...Api, currentUser }}>
        <AlertProvider template={AlertTemplate} {...alertOptions}>
          <RouterProvider router={router}>
            <Router></Router>
          </RouterProvider>
        </AlertProvider>
      </ApiContext.Provider>
    </div>
  )
}

export default IndexPage
