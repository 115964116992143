import React, { /*Suspense, lazy,*/ useEffect } from 'react'
import { useRoute } from 'react-router5'
import createRouter from 'router5'
import browserPlugin from 'router5-plugin-browser'
import { useApi } from '../components/Api'
import login from '../screens/login'
import home from '../screens/home'
import post from '../screens/post'
import postNotAllowed from '../screens/postNotAllowed'
import permissionsUpdated from '../screens/permissionsUpdated'
import terms from '../screens/terms'
import privacy from '../screens/privacy'

const routes = [
  {
    name: 'login',
    path: '/login',
    requireAuthentication: false,
    component: login, //lazy(() => import('../screens/login')),
  },
  {
    name: 'post-not-allowed',
    path: '/post/not-allowed',
    requireAuthentication: false,
    component: postNotAllowed,
  },
  {
    name: 'permissions-updated',
    path: '/permissions-updated/:postId',
    requireAuthentication: false,
    component: permissionsUpdated,
  },
  {
    name: 'post',
    path: '/post/:postId',
    requireAuthentication: true,
    component: post,
  },
  {
    name: 'home',
    path: '/home',
    requireAuthentication: true,
    component: home, //lazy(() => import('../screens/home')),
  },
  {
    name: 'terms',
    path: '/terms',
    requireAuthentication: false,
    component: terms, //lazy(() => import('../screens/home')),
  },
  {
    name: 'privacy',
    path: '/privacy',
    requireAuthentication: false,
    component: privacy, //lazy(() => import('../screens/home')),
  },
]

const router = createRouter(routes, {
  defaultRoute: 'home',
  queryParamsMode: 'loose',
})

router.usePlugin(browserPlugin())

export { router }

export function useNavigationCallback(route) {
  return (e) => {
    console.log(e)
    e.preventDefault()
    router.navigate(route)
    return true
  }
}

function Router() {
  const router5Context = useRoute()
  useEffect(() => {
    router.start()
  }, [])
  const { loggedIn } = useApi()
  const routeName = router5Context.route?.name
  if (!routeName) {
    return null
  }

  const route = routes.find(({ name }) => name === routeName)
  if (!route) {
    return null
  }

  if (!loggedIn() && route.requireAuthentication) {
    const loginRoute = routes.find(({ name }) => name === 'login')
    const next = window.location.pathname + window.location.search
    return <loginRoute.component next={next} />
  }

  return <route.component />
}

export default Router
