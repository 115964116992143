import React, { useRef, useEffect } from 'react'

export const useIsMounted = () => {
  const isMounted = useRef(false)
  useEffect(() => {
    isMounted.current = true
    return () => (isMounted.current = false)
  }, [])
  return () => isMounted.current
}

export const useAsyncEffect = (effect, dependencies, destroy) => {
  const hasDestroy = typeof destroy === 'function'

  React.useEffect(function () {
    let result
    let mounted = true
    const maybePromise = effect(function () {
      return mounted
    })

    Promise.resolve(maybePromise).then(function (value) {
      result = value
    })

    return function () {
      mounted = false

      if (hasDestroy) {
        destroy(result)
      }
    }
  }, dependencies) // eslint-disable-line
}

export default useIsMounted
