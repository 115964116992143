import React, { useContext } from 'react'
import { router } from './Router'
import dotenv from 'dotenv'
import { reportError } from '../Logger'

dotenv.config()

const headers = {
  'Content-Type': 'application/json',
}
const mode = 'cors'
const ROOT_URL = process.env.REACT_APP_API_URL

function setBearerToken(token) {
  localStorage.setItem('token', 'JWT ' + token)
  headers.Authorization = 'JWT ' + token
}

function getHashValue(key) {
  if (typeof window !== `undefined`) {
    const matches = window.location.hash.match(new RegExp(key + '=([^&]*)'))
    return matches ? matches[1] : null
  }
}

function loggedIn() {
  return !!headers.Authorization
}

function logout() {
  localStorage.removeItem('token')
  headers.Authorization = null
}

async function getRequest(path) {
  const response = await fetch(ROOT_URL + path, {
    method: 'GET',
    mode, // no-cors, *cors, same-origin
    headers,
  })

  if (response.status === 401) {
    logout()
    router.navigate('login')
  }

  if (response.status === 403) {
    throw Error('Forbidden')
  }

  if (response.status === 200) {
    return response.json()
  }

  return null
}

async function postRequest(path, body, headersOverride) {
  try {
    const response = await fetch(ROOT_URL + path, {
      method: 'POST',
      mode, // no-cors, *cors, same-origin
      headers: { Authorization: headers.Authorization, ...headersOverride },
      body,
    })

    if (response.status === 401) {
      logout()
      router.navigate('login')
    }

    if (response.status === 200) {
      return response.json()
    }

    return null
  } catch (e) {
    reportError(e, 'postRequest')
    return null
  }
}

async function getPosts() {
  return getRequest('/posts')
}

async function getPostById(postId) {
  return getRequest(`/posts/${postId}`)
}

async function resumePost(postId, triggerId) {
  return getRequest(`/resume-post/${postId}`)
}

async function getMe() {
  return getRequest('/me')
}

async function uploadImages(postId, files) {
  const requests = []
  for (const file of files) {
    const formData = new FormData()
    formData.append('image', file, file.name)
    requests.push(postRequest(`/upload-media/${postId}`, formData))
  }

  // Execute upload of multiple images in parallel
  return Promise.all(requests)
}

async function uploadPhotoTaken(postId, blob) {
  const formData = new FormData()
  formData.append('image', blob, 'photo')
  return postRequest(`/upload-media/${postId}`, formData)
}

async function deletePostImage(postId, imageUrl) {
  return postRequest(`/delete-media/${postId}`, JSON.stringify({ imageUrl }), {
    'Content-Type': 'application/json',
  })
}

const token = getHashValue('token')
if (token) {
  setBearerToken(token)
  if (typeof window !== `undefined`) {
    window.location.hash = ''
  }
}
headers.Authorization = localStorage.getItem('token')

const Api = {
  getPosts,
  getPostById,
  getMe,
  resumePost,
  loggedIn,
  logout,
  uploadImages,
  uploadPhotoTaken,
  deletePostImage,
  currentUser: null,
}
const ApiContext = React.createContext(Api)

const useApi = () => useContext(ApiContext)

export { ApiContext, Api, useApi }
