import React from 'react'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import { Link } from 'react-router5'
import Copyright from '../components/Copyright'
import { useApi } from '../components/Api'
import Header from '../components/Header'
import { css } from '@emotion/core'
import { useIsMounted, useAsyncEffect } from '../hooks'
import moment from 'moment'
import Emoji from 'react-emoji-render'
import LoadingSpinner from '../components/LoadingSpinner'
import { getTeamCategoryRenderString } from '../helpers'

const getImageUrl = (mediaUrl, gallery) => {
  if (gallery.length) {
    return gallery[0].image_url
  }
  return mediaUrl
}

const Item = ({
  id,
  slack_user_id,
  slack_channel_id,
  created_at,
  message,
  avatar_url,
  user_name,
  display_name,
  channel_name,
  team,
  subcategory,
  media_url,
  gallery,
}) => (
  <li
    css={css`
      display: flex;
      flex-direction: row;
      justify-content: top;
      align-items: top;
    `}
  >
    <div style={{ display: 'inline-block', width: '10%' }}>
      <span
        css={css`
          font-weight: 'bold';
          font-size: 16;
          position: absolute;
          z-index: 9;
          background-color: #f2f2f2;
          padding: 5px 0;
          margin: 30px 0;
        `}
      >
        {moment(created_at).format(' hh:mm A')}
      </span>
    </div>
    <div
      style={{
        display: 'inline-block',
        position: 'relative',
        width: '90%',
        margin: 10,
        backgroundColor: 'white',
        boxShadow:
          '0px 1px 5px rgba(0, 0, 0, 0.13), 0px 2px 4px rgba(0, 0, 0, 0.2)',
        padding: '16px 23px',
      }}
    >
      <Link routeName="post" routeParams={{ postId: id }}>
        <div
          css={css`
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 19px;
            opacity: 0.54;
          `}
        >
          {user_name} rang the Airgong in{' '}
          {channel_name ? '#' + channel_name : ' a private IM'}{' '}
          {getTeamCategoryRenderString(team, subcategory)}
        </div>
      </Link>
      <div
        css={css`
          display: flex;
          flex-direction: row;
          margin-top: 15px;
          align-content: stretch;
        `}
      >
        <img
          src={avatar_url}
          css={css`
            margin: 0 10px 0 0;
            margin-bottom: 0px;
          `}
          width="36"
          height="36"
          alt="Avatar"
        />
        <div
          css={css`
            display: inline-block;
          `}
        >
          <div>
            <span
              css={css`
                font-size: 16px;
                line-height: 19px;
              `}
            >
              {user_name}
            </span>
            <span
              css={css`
                font-size: 12px;
                color: #9e9ea6;
              `}
            >
              {moment(created_at).format(' hh:mm A')}
            </span>
            <div
              css={css`
                display: flex;
                flex-direction: column;
                font-size: 14px;
                line-height: 16px;
                color: #757575;
              `}
            >
              <Emoji text={message} />
              {getImageUrl(media_url, gallery) && (
                <img
                  src={getImageUrl(media_url, gallery)}
                  alt="Celebration Media"
                  css={css`
                    padding-top: 5px;
                    max-width: 200px;
                  `}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  </li>
)

export default function Home() {
  const { getPosts, currentUser } = useApi()

  const [loading, setLoading] = React.useState(true)
  const [posts, setPosts] = React.useState([])
  const isMounted = useIsMounted()

  useAsyncEffect(async () => {
    const response = await getPosts()
    if (isMounted()) {
      setPosts(response)
      setLoading(false)
    }
  }, [])

  return (
    <>
      <Header loggedUser={currentUser} />
      <Container component="main" maxWidth="md" min>
        {loading && <LoadingSpinner />}
        <ul
          css={css`
            list-style-type: none;
            position: relative;
            margin: 10px 0;
            ::after {
              content: '';
              position: absolute;
              width: 6px;
              background-color: #e0e0e0;
              height: 100%;
              left: 70px;
              top: 20px;
              bottom: 20px;
              border-radius: 2px;
            }
            li {
              background-color: #f2f2f2;
            }
          `}
        >
          {posts === null && !loading
            ? 'An error ocurred while loading posts'
            : null}
          {posts?.length === 0 && !loading ? 'No posts yet' : null}
          {posts?.length > 0 ? posts.map((post) => <Item {...post} />) : null}
        </ul>
        <Box mt={5}>
          <Copyright />
        </Box>
      </Container>
    </>
  )
}
