export const getTeamCategoryRenderString = (team, subcategory) => {
  if (team && subcategory) {
    return `( ${team} | ${subcategory} )`
  } else if (team) {
    return `( ${team} )`
  } else if (subcategory) {
    return `( ${subcategory} )`
  }
  return ''
}
