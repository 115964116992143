import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import * as serviceWorker from './serviceWorker'
import * as Sentry from '@sentry/browser'
import dotenv from 'dotenv'
dotenv.config()

const env = process.env.REACT_APP_ENVIRONMENT

if (process.env.REACT_APP_SENTRY_DSN) {
  if (['production', 'staging'].includes(env)) {
    Sentry.init({
      dsn: process.env.REACT_APP_SENTRY_DSN,
      environment: env,
    })
  }
} else {
  console.warn('Sentry DSN is not set')
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
