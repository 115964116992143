import React from 'react'
import { Dropzone } from './Dropzone'
import { reportError } from '../Logger'
import { useApi } from '../components/Api'
import { useAlert } from 'react-alert'

export const MAX_NUMBER_OF_IMAGES = 3

export default function ImagesUpload({
  postId,
  curImagesLength,
  onUploadFinish,
}) {
  const { uploadImages } = useApi()
  const alert = useAlert()
  const [isUploading, setIsUploading] = React.useState(false)
  const DropzoneRef = React.useRef(null)

  const handleImageUpdate = async (files) => {
    if (
      !files.length ||
      files.length + curImagesLength > MAX_NUMBER_OF_IMAGES
    ) {
      alert.error(
        `Max number of images allowed per post is ${MAX_NUMBER_OF_IMAGES}.`
      )
      return
    }

    for (const file of files) {
      // Check image size
      if (file.size > 3_000_000) {
        alert.error('Please select an image smaller than 3MB.')
        return
      }
      // Check image file type
      if (!file.type.includes('image')) {
        alert.error('Invalid file type. Please upload an image file.')
        return
      }
    }
    setIsUploading(true)
    try {
      const uploaded = await uploadImages(postId, files)
      onUploadFinish && onUploadFinish(uploaded)
    } catch (err) {
      alert.error(
        'There was an error uploading the image. Please try again later!'
      )
      reportError(err, 'Error while uploading images')
    }
    setIsUploading(false)
  }

  return (
    <Dropzone
      ref={DropzoneRef}
      onFilesAdded={handleImageUpdate}
      isUploading={isUploading}
    >
      <div className="image-content">{isUploading ? 'Uploading...' : null}</div>
    </Dropzone>
  )
}
