import * as Sentry from '@sentry/browser'

export const reportError = (error, context) => {
  if (!(error instanceof Error)) {
    try {
      // get a synthetic stacktrace
      if (typeof error === 'object') {
        throw new Error(JSON.stringify(error))
      }
      throw new Error(error)
    } catch (err) {
      error = err
    }
  }

  Sentry.configureScope((scope) => {
    if (context) {
      scope.setTag('context', context)
    }
  })
  Sentry.captureException(error)

  context ? console.error('error in ' + context, error) : console.error(error)
}

export const reportErrorWithCtx = (context) => (error) =>
  reportError(error, context)
